export const UBEYA_INTERNAL_ACCOUNT_ID = 8;
export const DYNAMO_ACCOUNT_ID = 147;
export const ETZ_HASADE_ACCOUNT_ID = 262;
export const ARC_ACCOUNT_ID = 179;
export const RH_ACCOUNT_ID = 3276;
export const UBEYA_ON_DEMAND_ACCOUNT_ID = 6786;
export const UBEYA_AND_MAOF_ACCOUNT_ID = 43604;
export const DESTINO_ACCOUNT_ID = 23051;
export const PRO_DRINKS_ACCOUNT_ID = 10355;
export const ALPHA_ACCOUNT_ID = 6673;
export const LABOR_DESK_ACCOUNT_ID = 25001;
export const DROR_DEMO_ACCOUNT_ID = 11571;
export const SPICE_ACCOUNT_ID = 158;
export const LA_MORRA_PIZZA_ACCOUNT_ID = 22796;
export const THE_ALL_STAR_AGENCY_ACCOUNT_ID = 12398;
export const MONTPERI_ACCOUNT_ID = 10988;
export const YORDEI_HASIRA_ACCOUNT_ID = 1795;
export const FRIENDS_ACCOUNT_ID = 12510;
export const LIMELIGHT_ACCOUNT_ID = 10153;
export const MERCO_ACCOUNT_ID = 13354;
export const PERCENT_110_ACCOUNT_ID = 4566;
export const EVENTO_ACCOUNT_ID = 4571;
export const PREMIUM_EVENT_STAFF_ACCOUNT_ID = 15880;
export const SUPPORT_NINJA_ACCOUNT_ID = 6652;
export const TEMP_STAFF_ACCOUNT_ID = 10504;
export const FENIX_CARE_ACCOUNT_ID = 17580;
export const TRING_CARE_ACCOUNT_ID = 18765;
export const PRICELESS_ONE_ACCOUNT_ID = 18855;
export const OBE_ACCOUNT_ID = 13705;
export const CONSTELATION_NL_ACCOUNT_ID = 32624;
export const JCA_ACCOUNT_ID = 32795;
export const ONB_ACCOUNT_ID = 22393;
export const GLOWTOUCH_ACCOUNT_ID = 21538;
export const CONSTELATION_ACCOUNT_ID = 23040;
export const CONSTELATION_TEST_ACCOUNT_ID = 19139;
export const CONSTELLATION_IRELAND_ACCOUNT_ID = 35003;
export const CONSTELLATION_JOCKEY_ACCOUNT_ID = 35002;
export const CHEFS_GARNED_CATERING_AND_EVENTS_ACCOUNT_ID = 2587;
export const XERO_FORMAT_ACCOUNT_IDS = [1, 8, 16636, 6673, 18351, 11253, 11571, 25440];
export const KAPAIM_ACCOUNT_ID = 24964;
export const DELAWARE_NORTH_ID = 32232;
export const WEMBLEY_BRANCH_ID = 32920;
export const FIRE_GROUP_ACCOUNT_ID = 25440;
export const HULLKR_ACCOUNT_ID = 27141;
export const BRENTFORD_ACCOUNT_ID = 24394;
export const LCC_ACCOUNT_ID = 23380;
export const VCP_ACCOUNT_ID = 25039;
export const BAXTER_STOREY_ACCOUNT_ID = 32934;
export const LEVY_TRAINING_ACCOUNT_ID = 35763;
export const EVENTIST_ACCOUNT_ID = 27794;
export const FOUR_LEISURE_ACCOUNT_ID = 32496;
export const MINT_ACCOUNT_ID = 3315;
export const GOODWOOD_ACCOUNT_ID = 24407;
export const CHELSEA_FC_ACCOUNT_ID = 24397;
export const WIMBELDON_ACCOUNT_ID = 24436;
export const NAHUMI_DEMO_ACCOUNT = 12812;
export const SILVERSTONE_ACCOUNT_ID = 48988;

export const KIA_OVAL_ACCOUNT_ID = 24420;
export const O2_ACCOUNT_ID = 24433;
export const CARDIFF_PRINCIPALITY_ACCOUNT_ID = 25147;
export const EDGBASTON_ACCOUNT_ID = 24405;
export const LEICESTER_TIGERS_ACCOUNT_ID = 24412;
export const RANGERS_FC_ACCOUNT_ID = 24422;
export const SEC_SCOTTISH_ACCOUNT_ID = 24428;
export const TOTTENHAM_ACCOUNT_ID = 27071;
export const JOCKEY_CLUB_CATERING_ACCOUNT_ID = 39287;
export const LEVY_TRAINING_SUPPLIER_ACCOUNT_ID = 40525;
export const CONSTELLATION_PLATINUM_ACCOUNT_ID = 40606;
export const BIRMINGHAM_CITY_FC_ACCOUNT_ID = 40355;
export const HARLEQUINS_ACCOUNT_ID = 24410;
export const QE_CALL_2_CENTER_ACCOUNT_ID = 24423;
export const THE_NATIONAL_THEATRE_ACCOUNT_ID = 24427;
export const OVO_WEMBLEY_ARENA_ACCOUNT_ID = 24432;
export const TWICKENHAM_ACCOUNT_ID = 24435;
export const ASTON_VILLA_FC_ACCOUNT_ID = 24393;
export const CARDIFF_CITY_FC_ACCOUNT_ID = 24400;
export const NEWBURY_RACECOURSE_ACCOUNT_ID = 24413;
export const LEICESTER_CITY_FC_ACCOUNT_ID = 24415;
export const NORTHAMPTON_SAINTS_ACCOUNT_ID = 24416;
export const SHEFFIELD_UNITED_FC_ACCOUNT_ID = 24429;
export const SWANSEA_CITY_FC_ACCOUNT_ID = 24430;
export const WOLVES_FC_ACCOUNT_ID = 24437;
export const EICC_ACCOUNT_ID = 25114;
export const BURNLEY_FC_ACCOUNT_ID = 26886;
export const JCA_CLIENT_ACCOUNT_ID = 32795;
export const JCA_CAFE_CLIENT_ACCOUNT_ID = 41082;
export const EXCEL_ACCOUNT_ID = 24402;
export const PREMIER_STAFF_ACCOUNT_ID = 38454;
export const PAYNE_N_GUNTER_ACCOUNT_ID = 45852;
export const CH_CO_PREMIER_CREW_ACCOUNT_ID = 49762;
export const CH_CO_COMPANY_OF_COLLEAGUES_ACCOUNT_ID = 49755;

export const LSS_MIDLANDS_ACCOUNT_ID = 46766;
export const LSS_LONDON_ACCOUNT_ID = 46767;
export const LSS_SCOTLAND_ACCOUNT_ID = 46768;
export const LEVY_CLENING_CHELSEA_ACCOUNT_ID = 46796;

export const IRELAND_HORSE_RACING_ACCOUNT_ID = 43522;
export const IRELAND_AVIVA_CLEANING_ACCOUNT_ID = 43520;
export const IRELAND_AVIVA_STADIUM_ACCOUNT_ID = 35007;
export const IRELAND_CONFERENCE_CENTRE_DUBLIN_ACCOUNT_ID = 43521;

export const KEITH_PROWSE_ACCOUNT_ID = 48001;

export const LEVY_SUPER_ADMIN_IDS = [14586, 5565, 7697, 14488, 7621, 14568, 14488, 14487];

export const UTRECHT_ACCOUNT_ID = 48233;

export const ELEGANT_CUISINE_ACCOUNT_ID = 40868;

// CH&CO accounts
export const CH_CO_TRAINING_PLATFORM_ACCOUNT_ID = 48836;
export const CHARLTON_ATHLETIC_FC_ACCOUNT_ID = 49216;
export const SOUTHAMPTON_FC_ACCOUNT_ID = 49217;
export const WATFORD_FC_ACCOUNT_ID = 49218;
export const PETERBOROUGHT_UNITED_FC_ACCOUNT_ID = 49219;
export const OLYMPIA_ACCOUNT_ID = 49220;

export const PEPPERMINT_ACCOUNT_ID = 50519;

export const CONSTELLATION_ACCOUNT_IDS = [
  // to move
  CH_CO_TRAINING_PLATFORM_ACCOUNT_ID,
  DELAWARE_NORTH_ID,
  // finish to move
  CONSTELATION_ACCOUNT_ID,
  CONSTELATION_TEST_ACCOUNT_ID,
  CONSTELLATION_JOCKEY_ACCOUNT_ID,

  LEVY_TRAINING_ACCOUNT_ID,
  GOODWOOD_ACCOUNT_ID,
  CHELSEA_FC_ACCOUNT_ID,
  WIMBELDON_ACCOUNT_ID,
  LCC_ACCOUNT_ID,
  BRENTFORD_ACCOUNT_ID,
  KIA_OVAL_ACCOUNT_ID,
  O2_ACCOUNT_ID,
  CARDIFF_PRINCIPALITY_ACCOUNT_ID,
  EDGBASTON_ACCOUNT_ID,
  LEICESTER_TIGERS_ACCOUNT_ID,
  RANGERS_FC_ACCOUNT_ID,
  SEC_SCOTTISH_ACCOUNT_ID,
  TOTTENHAM_ACCOUNT_ID,
  JOCKEY_CLUB_CATERING_ACCOUNT_ID,
  LEVY_TRAINING_SUPPLIER_ACCOUNT_ID,
  CONSTELLATION_PLATINUM_ACCOUNT_ID,
  BIRMINGHAM_CITY_FC_ACCOUNT_ID,
  HARLEQUINS_ACCOUNT_ID,
  QE_CALL_2_CENTER_ACCOUNT_ID,
  THE_NATIONAL_THEATRE_ACCOUNT_ID,
  OVO_WEMBLEY_ARENA_ACCOUNT_ID,
  TWICKENHAM_ACCOUNT_ID,
  ASTON_VILLA_FC_ACCOUNT_ID,
  CARDIFF_CITY_FC_ACCOUNT_ID,
  NEWBURY_RACECOURSE_ACCOUNT_ID,
  LEICESTER_CITY_FC_ACCOUNT_ID,
  NORTHAMPTON_SAINTS_ACCOUNT_ID,
  SHEFFIELD_UNITED_FC_ACCOUNT_ID,
  SWANSEA_CITY_FC_ACCOUNT_ID,
  WOLVES_FC_ACCOUNT_ID,
  EICC_ACCOUNT_ID,
  BURNLEY_FC_ACCOUNT_ID,
  EXCEL_ACCOUNT_ID,
  PAYNE_N_GUNTER_ACCOUNT_ID,
  LSS_MIDLANDS_ACCOUNT_ID,
  LSS_LONDON_ACCOUNT_ID,
  LSS_SCOTLAND_ACCOUNT_ID,
  LEVY_CLENING_CHELSEA_ACCOUNT_ID,
  KEITH_PROWSE_ACCOUNT_ID,

  // Ireland
  CONSTELLATION_IRELAND_ACCOUNT_ID,
  IRELAND_HORSE_RACING_ACCOUNT_ID,
  IRELAND_AVIVA_CLEANING_ACCOUNT_ID,
  IRELAND_AVIVA_STADIUM_ACCOUNT_ID,
  IRELAND_CONFERENCE_CENTRE_DUBLIN_ACCOUNT_ID,

  PEPPERMINT_ACCOUNT_ID
];

export const ALLOW_ADDRESS_EDITABLE_EMPLOYEE_ACCOUNT_IDS = [
  8,
  NAHUMI_DEMO_ACCOUNT,
  LABOR_DESK_ACCOUNT_ID,
  179,
  12398,
  30951,
  27794,
  11520,
  ARC_ACCOUNT_ID,
  35481
];

export const DISABLE_CLIENT_SELECT_IN_ORDER = [BAXTER_STOREY_ACCOUNT_ID];

export const MOTUS_ONE_ACCOUNT_ID = 45;
export const FREEMANS_ACCOUNT_ID = 3362;

// LIVE VIEW PERMISSIONS
export const ALLOW_PROJECT_LIVE_VIEW_ACCOUNT_IDS = [
  ...CONSTELLATION_ACCOUNT_IDS,
  JCA_ACCOUNT_ID,
  UTRECHT_ACCOUNT_ID,

  JCA_CLIENT_ACCOUNT_ID,
  JCA_CAFE_CLIENT_ACCOUNT_ID,
  CONSTELATION_NL_ACCOUNT_ID,
  HULLKR_ACCOUNT_ID,
  VCP_ACCOUNT_ID,
  DELAWARE_NORTH_ID,
  LEVY_TRAINING_ACCOUNT_ID,
  ARC_ACCOUNT_ID,
  EVENTIST_ACCOUNT_ID,
  MINT_ACCOUNT_ID,
  FOUR_LEISURE_ACCOUNT_ID,
  ALPHA_ACCOUNT_ID,
  ELEGANT_CUISINE_ACCOUNT_ID,
  // Suf's account prod
  38484,
  // DEV ACCOUNT
  164,

  MOTUS_ONE_ACCOUNT_ID,
  FREEMANS_ACCOUNT_ID,
  SILVERSTONE_ACCOUNT_ID
];

export const ALLOW_PROJECT_LIVE_VIEW_ORGANIZATIONS_IDS = [4];

// 3562 - access dmc
const ACCESS_DMC_ACCOUNT_ID = 3562;
export const COASTAL_FORMAT_ACCOUNT_IDS = [ACCESS_DMC_ACCOUNT_ID];

const RONIT_FARM_DAILY_ACCOUNT_ID = 22981;
export const INCLUDE_DUMMY_PAYROLL = [RONIT_FARM_DAILY_ACCOUNT_ID];

export const ACCOUNTS_BLOCK_BOOKING_NO_POSITION = [35002];

export const ALLOWED_ACCOUNTS_FOR_SALARY_RESOURCING_EDGE_REPORT = [THE_ALL_STAR_AGENCY_ACCOUNT_ID, 36599];

export const SHOW_AGE_WARNINGS = [
  ...CONSTELLATION_ACCOUNT_IDS,
  ALPHA_ACCOUNT_ID,
  JCA_ACCOUNT_ID,
  UTRECHT_ACCOUNT_ID,
  JCA_CLIENT_ACCOUNT_ID,
  JCA_CAFE_CLIENT_ACCOUNT_ID
];

export const DEFAULT_COST_CENTER_IN_SHIFT_REQUIRED = [
  CONSTELATION_ACCOUNT_ID,
  CONSTELLATION_PLATINUM_ACCOUNT_ID,
  CONSTELLATION_IRELAND_ACCOUNT_ID,
  CH_CO_PREMIER_CREW_ACCOUNT_ID,
  CH_CO_COMPANY_OF_COLLEAGUES_ACCOUNT_ID
];

export const DN_PRIVILAGED_ADMINS = [7577, 14581, 13190, 14580];

export const LEVY_CONSTELATION_ORGANIZATION_ID = 1;
export const LEVY_NL_CONSTELATION_ORGANIZATION_ID = 2;
export const LEVY_IR_CONSTELATION_ORGANIZATION_ID = 3;
export const CHCO_ORGANIZATION_ID = 4;

export const DELAWARE_NORTH_EVENT_DAY_TAG_ID = 3820;

//miriels@ubeya.com
export const ALLOWED_OPERATION_ADMINS_IDS_NOT_UBEYA_ADMIN = [21516];

// Cube Staffing - 31975
export const CUBE_STAFFING_ACCOUNT_ID = 31975;

export const FLEXIBLE_WORKFORCE_SOLUTION_ACCOUNT_ID = 48845;

export const DELAWARE_NORTH_EMIRATES_BRANCH_ID = 35903;
export const WIMBLEDON_CHAMPIONS_BRANCH_ID = 47144;
