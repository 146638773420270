import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { TooltipBody } from './index';
import BaseTooltip from './Tooltip';

const Wrapper = styled.div.attrs(() => ({ className: 'text-with-tooltip-wrapper' }))`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

const Tooltip = styled(BaseTooltip)`
  word-break: break-all;
`;

const TextWithTooltip = ({
  children,
  className,
  enabled = true,
  hidden = true,
  spellCheck = false,
  tooltipPlacement = 'top',
  wrapperRef,
  tooltipBodyMaxWidth = 150,
  useScrollHeight = false,
  ...props
}) => {
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(true);
  const [text, setText] = useState();

  const updateOverflow = useCallback(
    (e) => {
      const el = e.target;

      const overflowCondition = useScrollHeight ? el.scrollHeight > el.clientHeight : el.scrollWidth > el.clientWidth;

      if (overflowCondition) {
        setHasOverflowingChildren(true);
        if (el.textContent !== text) {
          setText(el.textContent);
        }
      } else {
        setHasOverflowingChildren(false);
      }
    },
    [text, useScrollHeight]
  );

  if (!hidden) {
    return children;
  }

  if (!enabled) {
    return (
      <Wrapper className={className} {...props}>
        {children}
      </Wrapper>
    );
  }

  return (
    <Tooltip
      body={<TooltipBody maxWidth={tooltipBodyMaxWidth} body={text} />}
      disabled={!text || !hasOverflowingChildren}
      placement={tooltipPlacement}>
      <Wrapper className={className} onMouseEnter={updateOverflow} spellCheck={spellCheck} ref={wrapperRef} {...props}>
        {children}
      </Wrapper>
    </Tooltip>
  );
};

export default React.memo(TextWithTooltip);
