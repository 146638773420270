import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Flex } from '../../Flex';
import { baseInputStyle } from '../../Input/InputStyle';
import Chip from './Chip';

const Wrapper = styled.div.attrs(() => ({ className: 'select-container' }))`
  position: relative;
  flex: 1;
  align-self: flex-start;
  max-height: 115px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  overflow-y: scroll;
`;

const Container = styled(Flex).attrs(() => ({ className: 'select-chips' }))`
  flex-wrap: wrap;
  align-items: center;
  background-color: ${({ theme, $isDisabled }) => ($isDisabled ? theme.colors.grey1 : theme.colors.white)} !important;
`;

const Input = styled.input.attrs(() => ({ className: 'select-input' }))`
  ${baseInputStyle};
  flex: 1;
  background: transparent;
  border: none;
  min-height: 44px;
  min-width: 100px;

  background: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }

  &:hover:not(:focus, :disabled) {
    border: none;
  }

  &:focus {
    border: none;
  }
`;

const MultiValue = ({
  value,
  options,
  placeholder,
  search,
  setSearch,
  searchable = true,
  isDisabled,
  onClick,
  onRemoveChip,
  name: _name,
  onBlur,
  isOpen,
  ...restProps
}) => {
  // map over the value (instead the options) to keep the selection order
  const displayChips = useMemo(
    () => (value || []).map((v) => options.find((o) => o?.value === v)).filter((curr) => !!curr),
    [options, value]
  );

  return (
    <Wrapper onClick={onClick}>
      <Container $isDisabled={isDisabled}>
        {displayChips.map((chip) => (
          <Chip key={chip?.value} {...chip} isDisabled={isDisabled} onRemove={onRemoveChip} />
        ))}

        {searchable && (
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={isDisabled ? '' : placeholder}
            disabled={isDisabled}
            onBlur={(e) => {
              onBlur?.(e);
              !isOpen && setSearch('');
            }}
            {...restProps}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default MultiValue;
