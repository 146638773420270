import { createCachedSelector } from 're-reselect';
import { useQuery, useMutation } from 'react-query';
import * as api from '../../services/api';
import { mappedArray } from '../../utils/array';
import { SORT_BY_BILLING_STATUS, SORT_BY_STRING } from '../../utils/sorting';
import { QUERY_CACHE_TIME } from '../../constants';
import useAccount from './useAccount';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => data.storeKey,
  (data) => {
    const clients = data;
    const activeClients = (clients || []).filter(({ isDeleted }) => !isDeleted);
    const inactiveClients = (clients || []).filter(({ isDeleted }) => isDeleted);

    const clientsOptions = activeClients
      .reduce(
        (prev, { name, status, branches }) => [
          ...prev,
          ...branches.map((branch) => ({
            value: branch.id,
            label: name === branch.name ? name : `${name} - ${branch.name}`,
            status
          }))
        ],
        []
      )
      ?.sort((a, b) => SORT_BY_BILLING_STATUS(a, b) || SORT_BY_STRING('label')(a, b));

    const clientsMainOptions = (activeClients || [])
      .map(({ id, name }) => ({ value: id, label: name }))
      .sort((a, b) => SORT_BY_STRING('label')(a, b));

    const mappedClientBranches = (data || []).reduce(
      (prev, client) => ({
        ...prev,
        ...(client?.branches || []).reduce(
          (all, { id, name, costCenters }) => ({
            ...all,
            [id]: {
              ...client,
              name: client.name === name ? client.name : `${client.name} - ${name}`,
              clientName: client.name,
              costCenters
            }
          }),
          {}
        )
      }),
      {}
    );

    const mappedClients = mappedArray(clients || []);
    const mappedClientsByAccountId = mappedArray(clients || [], (item) => item.accountId);

    return {
      clients,
      activeClients,
      inactiveClients,
      clientsOptions,
      mappedClientBranches,
      mappedClients,
      mappedClientsByAccountId,
      clientsMainOptions
    };
  }
)({
  keySelector: (data, storeKey) => storeKey
});

const useCRM = (props) => {
  const { withLastOrderDate = false, searchTerm = undefined, enabled = true } = props || {};

  const { accountId } = useAccount();
  const storeKey = ['clients', accountId, withLastOrderDate, searchTerm];

  const { isLoading, data } = useQuery(
    storeKey,
    () => api.fetchClients({ accountId, withLastOrderDate, searchTerm: searchTerm || undefined }),
    {
      cacheTime: QUERY_CACHE_TIME,
      select: (clientsData) => selector(clientsData, storeKey.join('#')),
      enabled
    }
  );
  const {
    clients = [],
    activeClients = [],
    inactiveClients = [],
    clientsOptions = [],
    mappedClientBranches = {},
    mappedClients = {},
    mappedClientsByAccountId = {},
    clientsMainOptions = []
  } = data || {};

  const { mutateAsync: inviteClientAdmin } = useMutation(api.inviteClientAdmin);

  return {
    isLoading,
    clients,
    activeClients,
    inactiveClients,
    clientsOptions,
    mappedClientBranches,
    mappedClients,
    mappedClientsByAccountId,
    inviteClientAdmin,
    hasClients: clients.length > 0,
    clientsMainOptions
  };
};

export default useCRM;
