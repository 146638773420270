import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import '../services/i18n';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

export const RTL_MAP = {
  he: true
};

const useTranslatedApp = (language) => {
  const rtl = RTL_MAP[language];
  const { t, industryId } = useContext(ConfigContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    const langaugeByIndustry = `industry_${industryId}_${language}`;

    i18n.changeLanguage(langaugeByIndustry);

    moment.updateLocale('en', {
      monthsShort: [
        t('jan'),
        t('feb'),
        t('mar'),
        t('apr'),
        t('may'),
        t('jun'),
        t('jul'),
        t('aug'),
        t('sep'),
        t('oct'),
        t('nov'),
        t('dec')
      ],
      months: [
        t('long_jan'),
        t('long_feb'),
        t('long_mar'),
        t('long_apr'),
        t('long_may'),
        t('long_jun'),
        t('long_jul'),
        t('long_aug'),
        t('long_sep'),
        t('long_oct'),
        t('long_nov'),
        t('long_dec')
      ],
      weekdays: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
      weekdaysShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return rtl;
};

export default useTranslatedApp;
