import React, { useState, useMemo, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';
import Modal from '../Modal';
import { Flex, FlexColumn } from '../Flex';
import { Header2 } from '../Typography';
import BaseCheckBox from '../CheckBox';

const Column = styled(FlexColumn)`
  margin-right: 55px;
`;

const Category = styled(Header2)`
  margin-bottom: 44px;
`;

const CheckBox = styled(BaseCheckBox)`
  margin-bottom: 16px;
  input[type='checkbox'] {
    display: none;
  }
`;

const ColumnsMenu = ({
  columnsMenuTitle,
  allColumns,
  visibleColumns,
  onClose,
  updateVisibleColumns,
  resetToDefault,
  showReset = true,
  size,
  renderColumnsMenuExtraData,
  confirmText,
  cancelText
}) => {
  const { t } = useContext(ConfigContext);
  const [selectedColumns, setSelectedColumns] = useState(() => visibleColumns.map(({ dataKey }) => dataKey));
  const categories = useMemo(
    () =>
      groupBy(
        allColumns.filter((column) => column?.category),
        'category'
      ),
    [allColumns]
  );

  const handleToggleColumn = useCallback(
    (selectedId, checked) => {
      if (!checked) {
        setSelectedColumns(selectedColumns.filter((id) => id !== selectedId));
      } else {
        setSelectedColumns([...selectedColumns, selectedId]);
      }
    },
    [selectedColumns]
  );

  return (
    <Modal
      size={size}
      onClose={onClose}
      title={columnsMenuTitle}
      confirmText={confirmText}
      onConfirm={() => updateVisibleColumns(selectedColumns)}
      showCancelButton={showReset}
      cancelText={cancelText || t('resetToDefault')}
      onCancel={() => {
        resetToDefault?.();
        onClose();
      }}
      usePortal>
      <FlexColumn>
        {renderColumnsMenuExtraData}
        <Flex>
          {Object.entries(categories).map(([category, columnsList]) => (
            <Column key={category}>
              {category !== ' ' && <Category>{category}</Category>}

              {columnsList.map(({ Header, dataKey, columnListLabel }, index) => (
                <CheckBox
                  key={`${dataKey}-${index}`}
                  name={dataKey}
                  label={t(columnListLabel || Header)}
                  value={selectedColumns.includes(dataKey)}
                  onChange={(checked) => handleToggleColumn(dataKey, checked)}
                />
              ))}
            </Column>
          ))}
        </Flex>
      </FlexColumn>
    </Modal>
  );
};

export default ColumnsMenu;
