import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip, TooltipBody } from '@ubeya/shared-web/components';
import { body, ellipsis } from '../../Typography';
import { ReactComponent as BaseClearIcon } from '../../../assets/close.svg';
import { baseInputStyle } from '../../Input/InputStyle';

const Wrapper = styled.div.attrs(() => ({ className: 'select-container' }))`
  position: relative;
  flex: 1;
  align-self: flex-start;
  align-items: center;
  display: flex;
  ${({ $isSearchable }) => !$isSearchable && 'padding-right: 40px;'}

  &:hover {
    .clear-icon {
      visibility: visible;
    }
`;

const Label = styled.label.attrs(() => ({ className: 'select-label' }))`
  ${body};
  ${ellipsis};
  position: absolute;
  top: 0;
  bottom: 0;
  max-width: 85%;
  display: inline-block;
  line-height: 25px;
  pointer-events: none;
  padding: 10px;

  ${({ theme, $isPlaceholder }) => $isPlaceholder && `color:${theme.colors.grey3}`};
  ${({ theme, $isDisabled }) => $isDisabled && `color:${theme.colors.grey3}`};
  ${({ $isSearchable }) =>
    !$isSearchable &&
    css`
      pointer-events: all;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
    `}
`;

const Input = styled.input.attrs(() => ({ className: 'select-input' }))`
  ${baseInputStyle};

  background: transparent;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }

  &:hover:not(:focus, :disabled) {
    border: none;
  }

  &:focus {
    border: none;
  }

  min-height: 44px;
`;

/*
This change introduces a wrapper function around BaseClearIcon. 
The wrapper destructure visible out of the props (using visible: _), 
preventing it from being forwarded to BaseClearIcon and ultimately rendered on a DOM node. 
This way, the component only passes the intended props, 
which helps avoid React 18 warnings about unrecognized props on DOM elements.
*/
const ClearIcon = styled(({ visible: _, ...rest }) => <BaseClearIcon {...rest} />).attrs(() => ({
  className: 'clear-icon'
}))`
  width: 11px;
  height: 19px;
  padding: 5px 8px;
  border-left: 1px solid ${({ theme }) => theme.colors.grey2};
  cursor: pointer;
  z-index: 500;
  ${({ visible }) => !visible && `visibility: hidden`}
`;

const Value = ({
  isRaw,
  value: selectedValue,
  options,
  inputRef,
  formatValue,
  placeholder,
  search,
  onClick,
  setSearch,
  isDisabled,
  // eslint-disable-next-line
  name,
  // eslint-disable-next-line
  onBlur,
  searchable,
  valuesComparator,
  // eslint-disable-next-line
  isOpen,
  clearable,
  onClear,
  // eslint-disable-next-line
  handleSelect,
  displayInitialValueNotInOptions = [],
  tooltipBody: baseTooltopBody = null,
  showValueAsTooltipBody = false,
  labelStyle,
  ...restProps
}) => {
  const displayValue = useMemo(() => {
    const selectedOption =
      options.find((option) => valuesComparator(option.value, selectedValue)) ||
      displayInitialValueNotInOptions.find((option) => valuesComparator(option.value, selectedValue));

    if (!selectedOption) {
      return undefined;
    }
    return formatValue?.({ value: selectedValue, displayValue: selectedOption.label }) || selectedOption.label;
  }, [formatValue, displayInitialValueNotInOptions, options, selectedValue, valuesComparator]);
  const value = isRaw ? selectedValue : displayValue;

  const tooltipBody = useMemo(() => (showValueAsTooltipBody ? value : baseTooltopBody), [
    baseTooltopBody,
    showValueAsTooltipBody,
    value
  ]);

  return (
    <Tooltip body={<TooltipBody body={tooltipBody} />} disabled={!tooltipBody}>
      <Wrapper onClick={isDisabled ? undefined : onClick} data-is-empty={!value} $isSearchable={searchable}>
        {!search && (
          <Label
            data-is-empty={!value}
            $isPlaceholder={!value}
            $isDisabled={isDisabled}
            $isSearchable={searchable}
            style={labelStyle || {}}>
            {value || placeholder}
          </Label>
        )}
        {searchable && (
          <Input
            ref={inputRef}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            disabled={isDisabled}
            autoComplete="new-password"
            {...restProps}
          />
        )}

        {!!value && clearable && !isDisabled && (
          <ClearIcon
            visible={restProps?.visible}
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          />
        )}
      </Wrapper>
    </Tooltip>
  );
};

export default Value;
