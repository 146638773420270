import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import { LineChart as BaseLineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import ConfigContext from '../../contexts/ConfigContext';

const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const LineChart = ({
  data,
  keys = [],
  legend = true,
  tickFormatter = (value) => moment(value).format('DD/MM'),
  tooltipFormatter,
  yAxisFontSize = 8,
  showCommas = false,
  lineColors = []
}) => {
  const theme = useTheme();
  const { t } = useContext(ConfigContext);
  const { isRtl } = useContext(ConfigContext);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <BaseLineChart
          width={width}
          height={height}
          data={data}
          margin={{ top: 10, left: 10, right: 10, bottom: legend ? 20 : 0 }}
          style={{ direction: isRtl ? 'ltr' : undefined }}>
          <CartesianGrid vertical={false} stroke={theme.colors.grey2} strokeWidth={1} />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12, fill: theme.colors.grey4 }}
            tickFormatter={tickFormatter}
          />
          <YAxis
            width={30}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            tick={{ fontSize: yAxisFontSize, fill: theme.colors.grey4 }}
            tickFormatter={(value) => value.toLocaleString()}
          />
          <Tooltip
            labelFormatter={tooltipFormatter}
            formatter={(value, name) => [showCommas ? numberWithCommas(Math.ceil(value)) : value, t(name)]}
          />
          {legend && <Legend formatter={(value) => t(value)} />}
          {keys.map((line, index) => (
            <Line
              key={line}
              type="monotone"
              dataKey={line}
              stroke={lineColors[index] || theme.colors.reportPalette[index % theme.colors.palette.length]}
              activeDot={false}
            />
          ))}
        </BaseLineChart>
      )}
    </AutoSizer>
  );
};

export default LineChart;
