import React, { useContext, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import moment from 'moment';
import { lighten } from 'polished';
import { BarChart as BaseBarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import ConfigContext from '../../contexts/ConfigContext';

const BarChart = ({
  data,
  keys = [],
  axisX = 'date',
  tooltip = true,
  tooltipContent,
  tooltipFormatter,
  legend = true,
  barSize = 8,
  radius = 4,
  margin,
  yAxisProps = {},
  legendProps = {},
  tickFormatter = (value) => moment(value).format('DD/MM')
}) => {
  const theme = useTheme();
  const { t } = useContext(ConfigContext);
  const { isRtl } = useContext(ConfigContext);

  const customKeys = useMemo(() => keys.map((key) => (typeof key === 'string' ? { name: key } : key)), [keys]);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <BaseBarChart
          data={data}
          width={width}
          height={height}
          style={{ direction: isRtl ? 'ltr' : undefined }}
          margin={margin}>
          <defs>
            {customKeys.map(({ name, colors }, index) => (
              <linearGradient key={name} id={name} x1="0" y1="0" x2="0" y2="1">
                {colors ? (
                  colors.map((color, colorIndex) => <stop key={colorIndex} offset="0%" stopColor={color} />)
                ) : (
                  <>
                    <stop offset="0%" stopColor={theme.colors.reportPalette[index % theme.colors.palette.length]} />
                    <stop
                      offset="100%"
                      stopColor={lighten(0.2, theme.colors.reportPalette[index % theme.colors.palette.length])}
                    />
                  </>
                )}
              </linearGradient>
            ))}
          </defs>

          <CartesianGrid vertical={false} stroke={theme.colors.grey2} strokeWidth={1} />
          <XAxis
            dataKey={axisX}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12, fill: theme.colors.grey4 }}
            tickFormatter={tickFormatter}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 10, fill: theme.colors.grey4 }}
            tickCount={8}
            tickFormatter={(value) => value.toLocaleString()}
            {...yAxisProps}
          />
          {tooltip && (
            <Tooltip formatter={tooltipFormatter} content={tooltipContent} cursor={{ fill: 'transparent' }} />
          )}

          {legend && <Legend formatter={(value) => t(value)} {...legendProps} />}

          {customKeys.map(({ name, label, labelFormatter, ...key }) => (
            <Bar key={name} dataKey={name} fill={`url(#${name})`} barSize={barSize} radius={radius} {...key}>
              {label && (
                <LabelList
                  dataKey={label ?? name}
                  position="insideTop"
                  className={label}
                  formatter={labelFormatter}
                  style={{ fill: '#fff' }}
                />
              )}
            </Bar>
          ))}
        </BaseBarChart>
      )}
    </AutoSizer>
  );
};

export default BarChart;
