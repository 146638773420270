import React, { useMemo } from 'react';
import { AppSideBar } from '@ubeya/shared-web/components';
import { ReactComponent as LogoIcon } from '@ubeya/shared-web/assets/logo.svg';
import { ReactComponent as UsersIcon } from '@ubeya/shared-web/assets/users.svg';
import { ReactComponent as MobileIcon } from '@ubeya/shared-web/assets/add-employee.svg';
import { ReactComponent as AccountsIcon } from '@ubeya/shared-web/assets/order.svg';
import { ReactComponent as DollarIcon } from '@ubeya/shared-web/assets/dollar.svg';
import { ReactComponent as BillingHistoryIcon } from '@ubeya/shared-web/assets/compliance.svg';
import { ReactComponent as OperationsIcon } from '@ubeya/shared-web/assets/BookingBoard/warning.svg';
// import { ReactComponent as ReportsIcon } from '@ubeya/shared-web/assets/reports.svg';
import Profile from './Profile';

const SideBar = () => {
  const links = useMemo(
    () => [
      [{ label: 'dashboard', to: '/dashboard', icon: LogoIcon }],
      [
        { label: 'accounts', to: '/accounts', icon: AccountsIcon },
        // { label: 'logos', to: '/logos', icon: AccountsIcon },
        { label: 'admins', to: '/admins', icon: UsersIcon },
        { label: 'appUsers', to: '/appUsers', icon: MobileIcon },
        // { label: 'b2bClients', to: '/b2bClients', icon: BriefcaseIcon },
        // { label: 'reports', to: '/reports', icon: ReportsIcon },
        { label: 'billing', to: '/billing', icon: DollarIcon },
        { label: 'billingHistory', to: '/billingHistory', icon: BillingHistoryIcon },
        { label: 'operations', to: '/operations', icon: OperationsIcon }
      ]
    ],
    []
  );

  return (
    <AppSideBar links={links} shouldChangePageTitle={false}>
      <Profile />
    </AppSideBar>
  );
};

export default SideBar;
