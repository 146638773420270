import React, { useState, useCallback, useContext, useMemo } from 'react';
import useLocations from '@ubeya/shared/hooks/account/useLocations';
import DropDown from '../../DropDown';
import CheckBox from '../../CheckBox';
import FilterLayout from '../FilterLayout';
import ConfigContext from '../../../contexts/ConfigContext';

const WorkedInAreaFilter = ({ defaultValue, handleClose, onApply, onChange, search, hideLayout = false }) => {
  const { t } = useContext(ConfigContext);
  const { locationsOptions } = useLocations();

  const [values, setValues] = useState(defaultValue || []);

  const handleChange = useCallback(
    (value) => {
      const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
      setValues(newValues);
      onChange?.(newValues);
    },
    [onChange, values]
  );

  const filteredOptions = useMemo(
    () =>
      search
        ? locationsOptions.filter(
            ({ label }) =>
              t('workedInArea').toLowerCase().includes(search.toLowerCase()) ||
              (label && label.toLowerCase().includes(search.toLowerCase()))
          )
        : locationsOptions,
    [locationsOptions, search, t]
  );

  if (!filteredOptions.length) {
    return null;
  }

  return (
    <FilterLayout name={t('areas')} handleClose={handleClose} onApply={() => onApply(values)} hideLayout={hideLayout}>
      {filteredOptions.map(({ value, label }) => (
        <DropDown.Item key={value}>
          <CheckBox
            useTextWithTooltip
            name={`area-${value}`}
            value={values.includes(value)}
            label={label}
            onChange={() => handleChange(value)}
          />
        </DropDown.Item>
      ))}
    </FilterLayout>
  );
};

export default WorkedInAreaFilter;
