import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { premiumModal, plansModal } from '@ubeya/shared/atoms/shared';
import { Modal as BaseModal, Button } from '@ubeya/shared-web/components';
import ConfigContext from '@ubeya/shared-web/contexts/ConfigContext';

const ModalWrapper = styled.div`
  .modal-wrapper {
    z-index: 9999999;
  }
`;

const Modal = styled(BaseModal)`
  width: 600px;
  height: 300px;

  > .content {
    margin: -30px 40px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
  }
`;

const Body = styled.div``;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 22rem;
  line-height: 28rem;
  font-weight: 400;
`;
const Text = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14rem;
  font-weight: normal;
  line-height: 24rem;s
`;

const PremiumModal = () => {
  const { t } = useContext(ConfigContext);
  const [premiumModalPayload, setPremiumModalPayload] = useRecoilState(premiumModal);
  const setIsPlansModalOpen = useSetRecoilState(plansModal);

  const onClose = useCallback(() => setPremiumModalPayload(null), [setPremiumModalPayload]);

  const onSeePlansClick = () => {
    setIsPlansModalOpen(true);

    onClose();
  };

  return (
    <ModalWrapper>
      <Modal
        allowEmptyTitle
        onClose={onClose}
        showConfirmButton={false}
        closeOnClickOutside={false}
        isPaddingContent={false}>
        <Body>
          <Title>{t(`premiumModalTitle_${premiumModalPayload.featureType}`)}</Title>
          <Text>{t(`premiumModalBody_${premiumModalPayload.featureType}`)}</Text>
        </Body>
        <Button onClick={onSeePlansClick}>{t('seePlans')}</Button>
      </Modal>
    </ModalWrapper>
  );
};

export default PremiumModal;
